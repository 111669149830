import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Card, Col, Overlay, OverlayTrigger, Row, Tooltip } from 'react-bootstrap-5';
import { connect } from 'react-redux';
import { setOwners } from './store/actions/owners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft, faArrowRight, faCaretDown, faEnvelope, faPhone, faUser
} from '@fortawesome/pro-solid-svg-icons';
import Ownership from './owners/ownership';
import { ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL, PROPERTY_MODEL } from '../../constants';
import pluralize from 'pluralize';
import { fetchContact } from '../../helpers/connect_api';
import EmptyState from '../empty_state';
import { faCircleCheck, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import AddContact from './owners/add_contact';
import UnlockOwners from '../off_market_properties/owners/unlock_owners';
import NewActivityForm from './activity_forms/new_activity_form';

function ActivitySection({ callListId, callTemplates, insightsPropertyId, owners, recordId, recordType, setOwners }) {
  const [singleOwner, setSingleOwner] = useState();
  const [ownerPosition, setOwnerPosition] = useState(0);
  const [showContactSelect, setShowContactSelect] = useState(false);
  const triggerRef = useRef(null);
  const [initialActivity, setInitialActivity] = useState({});

  const shownOwner = singleOwner ? singleOwner : owners ? owners[ownerPosition] : undefined;
  const isProperty = recordType === PROPERTY_MODEL;

  useEffect(() => {
    if (!isProperty) {
      // This only happens when a call list item is just a contact and is only needed for
      // backwards compatability
      fetchContact(recordId).then((res) => {
        setSingleOwner({ contact: res });
        setOwnerPosition(0);
      });
    }
  }, []);

  useEffect(() => {
    if (owners && owners.length > 0) {
      setOwnerPosition(0);
    }
  }, [owners?.length]);

  const handleLogActivityClick = (activityType, contactMethod) => {
    let activityValue;
    let activityValueKey;
    if (activityType === ACTIVITY_TYPE_EMAIL) {
      activityValue = contactMethod.email;
      activityValueKey = 'email';
    } else if (activityType === ACTIVITY_TYPE_CALL) {
      activityValue = contactMethod.number;
      activityValueKey = 'phoneNumber';
    }

    setInitialActivity({ type: activityType, [activityValueKey]: activityValue });
  };

  const renderLogActivity = (contactMethodKey, activityValue) => {
    let tooltipLabel;
    let activityType;
    if (contactMethodKey === 'emails') {
      tooltipLabel = 'Log Email';
      activityType = ACTIVITY_TYPE_EMAIL;
    } else if (contactMethodKey === 'phoneNumbers') {
      tooltipLabel = 'Log Call';
      activityType = ACTIVITY_TYPE_CALL;
    }

    return (
      <OverlayTrigger overlay={<Tooltip>{tooltipLabel}</Tooltip>}>
        <div className="clickable p-1 ms-auto" onClick={() => handleLogActivityClick(activityType, activityValue)}>
          <FontAwesomeIcon
            className="text-secondary"
            icon={activityType === ACTIVITY_TYPE_EMAIL ? faEnvelope : faPhone}
          />
        </div>
      </OverlayTrigger>
    );
  };

  const togglePrevContact = () => {
    setInitialActivity({});
    if (ownerPosition === 0) {
      setOwnerPosition(owners.length - 1);
    } else {
      setOwnerPosition(ownerPosition - 1);
    }
  };

  const toggleNextContact = () => {
    setInitialActivity({});
    if (ownerPosition === owners.length - 1) {
      setOwnerPosition(0);
    } else {
      setOwnerPosition(ownerPosition + 1);
    }
  };

  const handleOwnerSelect = (selectedOwner) => {
    setOwnerPosition(owners.findIndex(owner => owner.contact.id === selectedOwner.contact.id));
    setShowContactSelect(false);
  };

  const popoverCard = (
    <Card style={{ maxWidth: '500px', minWidth: '320px' }}>
      {owners && (
        <React.Fragment>
          <Card.Header className="p-3">
            <div className="d-flex justify-content-between">
              <div>Contacts</div>
              <div className="text-muted">{owners.length} contacts</div>
            </div>
          </Card.Header>
          <Card.Body className="p-3">
            {owners.map(owner => (
              <div
                className="d-flex clickable p-2 align-items-center gap-2"
                key={owner.id}
                onClick={() => handleOwnerSelect(owner)}
              >
                <FontAwesomeIcon icon={faUserCircle} />
                <div>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="mb-0">{owner.contact.name}</div>
                    { !owner.verified ? null : (
                      <Badge className="fw-normal fs-small py-0-5 px-2" pill>
                        <div className="d-flex align-items-center text-white gap-1">
                          <FontAwesomeIcon className="text-white" icon={faCircleCheck} size="sm" />
                          <div>Verified Owner</div>
                        </div>
                      </Badge>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Card.Body>
        </React.Fragment>
      )}
    </Card>
  );

  if (isProperty && owners === null) {
    return (
      <Card>
        <Card.Body>
          <UnlockOwners insightsPropertyId={insightsPropertyId} onUnlock={setOwners} />
        </Card.Body>
      </Card>
    );
  }

  if (!shownOwner) {
    return (
      <Card>
        <Card.Body>
          <EmptyState icon={faUser} title={'No Contacts Found'} />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <React.Fragment>
        <Card.Header>
          <Row className="align-items-center gy-2">
            <Col>
              <div className="d-flex align-items-center gap-2">
                <h5 className="fw-bold mb-0">{shownOwner.contact.name}</h5>
                { !shownOwner.verified ? null : (
                  <Badge className="fw-normal fs-small py-0-5 px-2" pill>
                    <div className="d-flex align-items-center text-white gap-1">
                      <FontAwesomeIcon className="text-white" icon={faCircleCheck} />
                      Verified Owner
                    </div>
                  </Badge>
                )}
              </div>
            </Col>
            {owners && (
              <Col md="auto" xs={12}>
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <AddContact />
                    {owners.length > 1 && (
                      <React.Fragment>
                        <Button
                          variant="outline-primary"
                          onClick={togglePrevContact}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={toggleNextContact}
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                        <div
                          className="d-flex clickable align-items-center gap-1"
                          ref={triggerRef}
                          onClick={() => setShowContactSelect(true)}
                        >
                          {ownerPosition + 1} of {owners.length} {pluralize('Contact', owners.length)}
                          <FontAwesomeIcon icon={faCaretDown} size="sm" />
                        </div>
                        <Overlay
                          placement="bottom"
                          rootClose
                          show={showContactSelect}
                          target={triggerRef.current}
                          onHide={() => setShowContactSelect(false)}
                        >
                          {popoverCard}
                        </Overlay>
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              </Col>
            )}
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="g-6">
            <Col md={4}>
              <Ownership owner={shownOwner} renderLogActivity={renderLogActivity} />
            </Col>
            <Col md={8}>
              <NewActivityForm
                callListId={callListId}
                callTemplates={callTemplates}
                initialActivity={initialActivity}
                owner={shownOwner}
                propertyId={recordType === PROPERTY_MODEL ? recordId : undefined}
              />
            </Col>
          </Row>
        </Card.Body>
      </React.Fragment>
    </Card>
  );
}

ActivitySection.propTypes = {
  callListId: PropTypes.number.isRequired,
  callTemplates: PropTypes.array.isRequired,
  insightsPropertyId: PropTypes.number,
  owners: PropTypes.array,
  recordId: PropTypes.number.isRequired,
  recordType: PropTypes.string.isRequired,
  setOwners: PropTypes.func.isRequired,
  status: PropTypes.number
};

const mapStateToProps = ({ insightsPropertyId, owners }) => ({ insightsPropertyId, owners });
export default connect(mapStateToProps, { setOwners })(ActivitySection);
