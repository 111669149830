import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap-5';

import SessionModal from './session_modal';

export default function SignInCard() {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <Card style={{ backgroundColor: '#192755', borderColor: '#40434F', width: '230px' }}>
        <Card.Body className="d-flex flex-column gap-2">
          <strong className="text-white my-auto">Find more. Search less.</strong>

          <small className="text-white">
            Unlock saved search filters, full property data, and more with a free Buildout Connect account.
          </small>

          <Button variant="secondary" onClick={() => setShowModal(true)}>
            Create Account
          </Button>
        </Card.Body>
      </Card>

      {showModal && <SessionModal onHide={() => setShowModal(false)} />}
    </React.Fragment>
  );
}
