import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.nav-link').forEach((navElement) => {
      navElement.addEventListener('click', (e) => {
        const activeElement = this.element.querySelector('.active');
        if (activeElement) activeElement.classList.remove('active');

        if (!e.currentTarget.dataset.disableToggle) e.currentTarget.classList.add('active');
      });
    });
  }
}
