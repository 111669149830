export const SET_SHOW_LOGIN = 'SET_SHOW_LOGIN';

export function setShowLogin(show) {
  return {
    type: SET_SHOW_LOGIN,
    payload: show
  };
}

export const SET_SHOW_INFO_REQUEST = 'SET_SHOW_INFO_REQUEST';

export function setShowInfoRequest(show) {
  return {
    type: SET_SHOW_INFO_REQUEST,
    payload: show
  };
}

export const SET_USER_TYPE = 'SET_USER_TYPE';
export const USER_TYPE_BROKER = 'broker';
export const USER_TYPE_OWNER = 'owner';

export function isValidUserType(userType) {
  return userType === USER_TYPE_BROKER || userType === USER_TYPE_OWNER;
}
export function labelForUserType(userType) {
  switch (userType) {
  case USER_TYPE_BROKER:
    return 'Broker';
  case USER_TYPE_OWNER:
    return 'Principal/Investor';
  default:
    return '';
  }
}

export const SET_SHOW_NO_DOCS_BANNER = 'SET_SHOW_NO_DOCS_BANNER';

// see reducer for what `show` can be
export function setShowNoDocsBanner(show) {
  return {
    type: SET_SHOW_NO_DOCS_BANNER,
    payload: show
  };
}
