import '@hotwired/turbo-rails';

import 'bootstrap-5';

import 'components/page_header.scss';
import 'animate.css';
import 'trix';
import '@rails/actiontext';

import ShowDealRoom from 'bundles/connect/components/deal_room/show';
import SharingConnect from 'bundles/sharing_connect';
import MatchShow from 'bundles/connect/components/matches/match_show';
import MatchPhotoAndMap from 'bundles/connect/components/matches/review/match_photo_and_map';
import Search from 'bundles/connect/components/search';
import PasswordInput from 'bundles/connect/components/password_input';
import PhoneNumberInput from 'bundles/connect/components/phone_number_input';
import ProfileCompletion from 'bundles/connect/components/profile/completion';
import ProfileCompletionNew from 'bundles/connect/components/profile/completion_new';

// Work around for https://github.com/shakacode/react_on_rails/issues/991
window.ReactOnRails = window.ReactOnRails || require('react-on-rails').default;

import 'packs/global';

import { Application } from '@hotwired/stimulus';
import TableRowLinkController from 'bundles/connect/controllers/table_row_link_controller';
import ImageUploadPreviewController from 'bundles/connect/controllers/image_upload_preview_controller';
import FeedbackController from 'bundles/connect/controllers/feedback_controller';
import autoFormController from 'bundles/connect/controllers/auto_form_controller';
import autoFocusController from 'bundles/connect/controllers/auto_focus_controller';
import BsTabDeeplink from 'bundles/connect/controllers/bs_tab_deeplink';
import Tooltip from 'bundles/connect/controllers/tooltip';
import Offcanvas from 'bundles/connect/controllers/offcanvas';
import VisibilityToggle from 'bundles/connect/controllers/visibility_toggle';
import DisableToggle from '../bundles/connect/controllers/disable_toggle';
import ClickVisibilityToggle from 'bundles/connect/controllers/click_visibility_toggle';
import Lightbox from 'bundles/connect/controllers/lightbox';
import BootstrapNavActive from 'bundles/connect/controllers/bootstrap_nav_active';
import DisableWithController from 'bundles/connect/controllers/disable_with_controller';
import ToastNotice from 'bundles/connect/controllers/toast_notice';
import ToastEvent from 'bundles/connect/controllers/toast_event';
import Popper from 'bundles/connect/controllers/popper';
import FormValidationController from '../bundles/connect/controllers/form_validation_controller';
import GoogleMapController from '../bundles/connect/controllers/google_map';
import RemoveOnDelete from '../bundles/connect/controllers/remove_on_delete';
import InterestedResponseFormController from 'bundles/connect/controllers/forms/interested_response_form_controller';
import PropertyActions from 'bundles/connect/components/off_market_properties/property_actions';
import SignInCard from 'bundles/connect/components/sign_in_card';
import AlphamapsIframeResize from '../bundles/connect/controllers/alphamaps_iframe_resize';
import PropertyTabs from 'bundles/connect/controllers/property_tabs';
import FakeContacts from 'bundles/connect/components/off_market_properties/owners/fake_contacts';
import PropertyValuation from 'bundles/connect/components/search/components/property_valuation';
import GtagEventController from 'bundles/connect/controllers/gtag_event_controller';
import ShowProperty from 'bundles/connect/controllers/show_property';
import StreetViewModal from 'bundles/connect/components/search/components/street_view_modal';
import PropertyOffcanvasContainer from 'bundles/connect/components/search/components/property_offcanvas_container';
import OffMarketDetailsCard from 'bundles/connect/components/search/components/off_market_details_card';
import OffMarketPropertyOwnership from 'bundles/connect/components/off_market_properties/ownership';
import OffMarketComps from '../bundles/connect/components/search/components/off_market_comps';
import FormModalController from 'bundles/connect/controllers/form_modal_controller';
import AddProperty from 'bundles/connect/components/call_lists/add_property';
import CallListItem from '../bundles/connect/components/call_list/call_list_item';
import TasksIndex from 'bundles/connect/components/tasks/tasks_index';
import ContactsIndex from 'bundles/connect/components/contacts';
import MyPropertiesIndex from 'bundles/connect/components/my_properties/my_properties_index';
import { handleFrameUnload } from '../bundles/connect/helpers/turbo_react';
import PropertyOverviewActions from 'bundles/connect/components/properties/property_overview_actions';
import Owners from 'bundles/connect/components/off_market_properties/owners';
import TagSettings from 'bundles/connect/components/tags/settings';
import PropertyRecords from 'bundles/connect/components/off_market_properties/property_records';
import CallListExport from 'bundles/connect/components/call_lists/export';
import DataExportSettings from 'bundles/connect/components/data_exports/settings';

window.Stimulus = Application.start();
window.Stimulus.register('tableRowLink', TableRowLinkController);
window.Stimulus.register('imageUploadPreview', ImageUploadPreviewController);
window.Stimulus.register('feedback', FeedbackController);
window.Stimulus.register('autoForm', autoFormController);
window.Stimulus.register('autoFocus', autoFocusController);
window.Stimulus.register('tooltip', Tooltip);
window.Stimulus.register('offcanvas', Offcanvas);
window.Stimulus.register('visibilityToggle', VisibilityToggle);
window.Stimulus.register('disableToggle', DisableToggle);
window.Stimulus.register('clickVisibilityToggle', ClickVisibilityToggle);
window.Stimulus.register('bsTabDeeplink', BsTabDeeplink);
window.Stimulus.register('formValidation', FormValidationController);
window.Stimulus.register('lightbox', Lightbox);
window.Stimulus.register('bootstrapNavActive', BootstrapNavActive);
window.Stimulus.register('gtagEvent', GtagEventController);
window.Stimulus.register('disable', DisableWithController);
window.Stimulus.register('toastNotice', ToastNotice);
window.Stimulus.register('toastEvent', ToastEvent);
window.Stimulus.register('popper', Popper);
window.Stimulus.register('googleMap', GoogleMapController);
window.Stimulus.register('removeOnDelete', RemoveOnDelete);
window.Stimulus.register('interestedResponseForm', InterestedResponseFormController);
window.Stimulus.register('alphamapsIframeResize', AlphamapsIframeResize);
window.Stimulus.register('propertyTabs', PropertyTabs);
window.Stimulus.register('formModal', FormModalController);
window.Stimulus.register('showProperty', ShowProperty);

ReactOnRails.setOptions({ turbo: true });

ReactOnRails.register({
  CallListItem,
  MatchPhotoAndMap,
  MatchShow,
  Search,
  SharingConnect,
  SignInCard,
  ShowDealRoom,
  PasswordInput,
  PhoneNumberInput,
  ProfileCompletion,
  ProfileCompletionNew,
  FakeContacts,
  PropertyValuation,
  StreetViewModal,
  PropertyOffcanvasContainer,
  OffMarketDetailsCard,
  OffMarketPropertyOwnership,
  OffMarketComps,
  PropertyActions,
  AddProperty,
  TasksIndex,
  ContactsIndex,
  MyPropertiesIndex,
  PropertyOverviewActions,
  Owners,
  TagSettings,
  PropertyRecords,
  CallListExport,
  DataExportSettings
});

if (module.hot) {
  module.hot.accept('bundles/sharing_connect', () => ReactOnRails.reactOnRailsPageLoaded());
  module.hot.accept(
    'bundles/contact/requirements/requirements_form_container', () => ReactOnRails.reactOnRailsPageLoaded()
  );
  module.hot.accept('bundles/connect/components/search', () => ReactOnRails.reactOnRailsPageLoaded());
}

document.addEventListener('turbo:frame-render', (e) => {
  if (e.target.querySelector('.js-react-on-rails-component')) ReactOnRails.reactOnRailsPageLoaded();
});

document.addEventListener('turbo:load', () => {
  // When we press an element in the navigation bar,
  // after having entered the search page and rendered our react components,
  // visiting another page through a turbo_frame causes the component to be in the DOM but is not rendered.
  // Added the component that will allow us to display the property offcanvas from P&N iframes
  // or other places using the turbo:load event
  if (document.getElementById('property-offcanvas-container')) {
    ReactOnRails.render('PropertyOffcanvasContainer', {}, 'property-offcanvas-container');
  }
});

document.addEventListener('turbo:before-frame-render', (e) => {
  // When a frame is being rendered,
  // remove any react components loaded by react on rails that exist in the current frame
  handleFrameUnload(e.target);
});
