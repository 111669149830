import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap-5';
import { isValidUserType, labelForUserType } from '../../actions/ui';
import { setUserType } from '../../actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faLock, faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { get, post } from 'helpers/api';
import CaText from './ca_text';
import { FAST_TRACK_CA_USER_TYPE } from '../../helpers/vault_access_helper';
import GateAccountFields from './gates/account_fields';

export function FastSignCaText({ caText, fastTrackProfilePath, signCaPath, onNext, userType, setUserType }) {
  const [emailCa, setEmailCa] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loadingProfileData, setLoadingProfileData] = useState(true);
  const [formFields, setFormFields] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyName: '',
    jobTitle: '',
    countryId: 1,
  });

  function basicFormFieldChange(field, newValue) {
    setFormFields({ ...formFields, [field]: newValue });
  }

  const { email, firstName, lastName, phoneNumber, companyName, jobTitle, countryId } = formFields;

  const saveDisabled = saving || !email || !firstName || !lastName || !companyName || !jobTitle || !phoneNumber;

  const signCa = (e) => {
    e.preventDefault();

    setSaving(true);

    const userData = {
      userType,
      email,
      firstName,
      lastName,
      phoneNumber,
      companyName,
      jobTitle,
      countryId
    };
    post(signCaPath, { signCa: true, emailCa, userData }).then(onNext);
  };

  const backToUserType = () => {
    setUserType(null);
    onNext(FAST_TRACK_CA_USER_TYPE);
  };

  useEffect(() => {
    if (!isValidUserType(userType)) {
      onNext(FAST_TRACK_CA_USER_TYPE);
    }
  }, [userType]);

  useEffect(() => {
    get(fastTrackProfilePath).then((data) => {
      setLoadingProfileData(false);

      const filteredData = Object.keys(data).reduce((validData, key) => {
        if (data[key]) validData[key] = data[key];

        return validData;
      }, {});

      setFormFields(prevData => ({ ...prevData, ...filteredData }));
    });
  }, []);

  return (
    <div>
      <Button className="d-none d-lg-block" variant="link" onClick={backToUserType}>
        <FontAwesomeIcon className="me-2" icon={faChevronLeft} size="lg"/>
        {/* This doesn't say "Back" because we may have auto-set the role from the existing profile */}
        Change Role
      </Button>

      <div className="text-center mb-4 text-branding">
        <FontAwesomeIcon icon={faLock} size="2x"/>
      </div>
      <h5 className="text-center mb-4">
        <strong>Sign the CA to view this document</strong>
      </h5>
      <form onSubmit={signCa}>
        <CaText caText={caText}/>

        <div className="mb-4">
          <span className="fw-bolder">Role:</span> {labelForUserType(userType)}
        </div>

        {loadingProfileData ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} size="2x" spin={true}/>
          </div>
        ) : (
          <React.Fragment>
            <GateAccountFields
              emailTaken={false}
              formFields={formFields}
              isRegistration={false}
              readonly={false}
              onFormFieldChange={basicFormFieldChange}
            />
            <Form.Group className="mb-4">
              <Form.Check
                checked={emailCa}
                id="email-ca"
                label="Email me a copy of this CA"
                name="email_ca"
                type="checkbox"
                onChange={e => setEmailCa(e.target.checked)}
              />
            </Form.Group>
            <Button
              className="w-100 mt-2"
              disabled={saveDisabled}
              name="save"
              type="submit"
              variant="primary"
              onClick={signCa}
            >
              <FontAwesomeIcon className="me-2" icon={saving ? faSpinner : faPencilAlt} spin={saving}/>
              {saving ? 'Signing ...' : 'Sign CA'}
            </Button>
          </React.Fragment>
        )}
      </form>
    </div>
  );
}

FastSignCaText.propTypes = {
  caText: PropTypes.string.isRequired,
  fastTrackProfilePath: PropTypes.string.isRequired,
  setUserType: PropTypes.func.isRequired,
  signCaPath: PropTypes.string.isRequired,
  userType: PropTypes.string,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ ui }) => ({ userType: ui.userType });
const mapDispatchToProps = { setUserType };
export default connect(mapStateToProps, mapDispatchToProps)(FastSignCaText);
