import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { setUserType } from '../../../actions';
import { labelForUserType, USER_TYPE_BROKER, USER_TYPE_OWNER } from '../../../actions/ui';
import PreviewPortal from './index';

function UserTypePortal({ setUserType }) {
  const handleChoice = (choice) => {
    setUserType(choice);
  };

  return (
    <PreviewPortal>
      <Button variant="primary" onClick={() => handleChoice(USER_TYPE_BROKER)}>
        {labelForUserType(USER_TYPE_BROKER)}
      </Button>
      <Button variant="primary" onClick={() => handleChoice(USER_TYPE_OWNER)}>
        {labelForUserType(USER_TYPE_OWNER)}
      </Button>
    </PreviewPortal>
  );
}

UserTypePortal.propTypes = { setUserType: PropTypes.func.isRequired };
const mapDispatchToProps = { setUserType };
export default connect(null, mapDispatchToProps)(UserTypePortal);
