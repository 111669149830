import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { faBoxArchive } from '@fortawesome/pro-regular-svg-icons';

function formatDate(dateString) {
  const date = new Date(dateString);
  
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  
  return `${month}/${day}/${year}`;
}

function rowDetail(label, value) {
  if (value === undefined) return;
  return (
    <Row className="gx-0 border-bottom border-light align-items-center py-2">
      <Col lg={5}>
        {label}
      </Col>
      <Col>{value}</Col>
    </Row>
  );
}

function boolToString(value) {
  if (value) {
    return 'Yes';
  }

  return 'No';
}

export default function PropertyRecordRow({ collapsed, propertyRecord }) {
  const [open, setOpen] = useState(!collapsed);
  const mortgage1 =
    propertyRecord.mortgages && propertyRecord.mortgages.length > 0 ? propertyRecord.mortgages[0] : {};

  return (
    <div>
      <Row className="align-items-center clickable bottom-border" onClick={() => setOpen(!open)}>
        <Col>
          <FontAwesomeIcon className="text-secondary me-2" icon={faBoxArchive} />
          <strong>{propertyRecord.recordType} on {formatDate(propertyRecord.date)}</strong>
        </Col>
        <Col xs="auto">
          <FontAwesomeIcon icon={faAngleUp} rotation={open ? 180 : undefined} />
        </Col>
      </Row>
      <Collapse in={open}>
        <div>
          {rowDetail('Recording Date', formatDate(propertyRecord.date))}
          {rowDetail('Subdivision Name', propertyRecord.subdivisionName)}
          {rowDetail('Buyer/Borrower', propertyRecord.buyerOrBorrower)}
          {rowDetail('Document Type', propertyRecord.documentTypeDescription)}
          {rowDetail('Mortgage 1 Amount', mortgage1.amount !== 0 ? mortgage1.amount : undefined)}
          {rowDetail('Lender Name', mortgage1.lenderName !== '' ? mortgage1.lenderName : undefined)}
          {rowDetail('Loan Type', mortgage1.term !== 0 ? mortgage1.loanType : undefined)}
          {rowDetail('Term', mortgage1.term !== 0 ? mortgage1.term : undefined)}
          {rowDetail('Interest Rate Type', mortgage1.interestRate !== 0 ? mortgage1.interestRateType : undefined)}
          {rowDetail('Interest Rate', mortgage1.interestRate !== 0 ? mortgage1.interestRate : undefined)}
          {
            rowDetail(
              'Subordinate Loan',
              mortgage1.subordinateLoan !== undefined ? boolToString(mortgage1.subordinateLoan) : undefined
            )
          }
          {rowDetail('Title Company', mortgage1.titleCompany)}
        </div>
      </Collapse>
    </div>
  );
}

PropertyRecordRow.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  propertyRecord: PropTypes.object.isRequired
};
