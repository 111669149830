import { getQueryParameter, setQueryParameter } from 'helpers/browser_helpers';
import { findFileInFolder, findFolderInFolder, getFirstFile } from '../helpers/folder_helpers';

import { get } from 'helpers/api';

import { setShowLogin, SET_USER_TYPE } from './ui';

function parseAndSetCurrentFolderId(folderId) {
  const id = folderId === 0 ? null : folderId;
  // store this in a dataset so we can access it in RootFolderChangeNotifier without having to
  // resubscribe everytime this changes since this can be grabbed dynamically
  document.querySelector('body').dataset.currentFolderId = id;
  return id;
}

export const HANDLE_NAVIGATION = 'HANDLE_NAVIGATION';
export const handleNavigation = (folder) => {
  return (dispatch, getState) => {
    const prevState = getState();

    if (folder.locked) {
      dispatch({
        type: HANDLE_NAVIGATION,
        navigation: { ...prevState.navigation, currentFolderId: null, lockedFolderId: folder.id },
        file: null
      });
      return;
    }

    const file = getFirstFile(folder);
    setQueryParameter('file', (file && file.id));

    const currentFolderId = parseAndSetCurrentFolderId(folder.id);

    dispatch({
      type: HANDLE_NAVIGATION,
      navigation: { ...prevState.navigation, currentFolderId: currentFolderId, lockedFolderId: null },
      file
    });
  };
};

export const updateRootFolder = (data) => {
  return (dispatch, getState) => {
    const prevState = getState();

    dispatch({
      type: HANDLE_NAVIGATION,
      file: prevState.file,
      navigation: { ...prevState.navigation, rootFolder: data.rootFolder }
    });
  };
};

export const PREVIEW_FILE = 'PREVIEW_FILE';
export const previewFile = (file) => {
  return (dispatch) => {
    setQueryParameter('file', file.id);

    dispatch({ type: PREVIEW_FILE, file });
    dispatch(setShowLogin(false));
  };
};

export function setUserType(userType) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_TYPE,
      payload: userType
    });
  };
}

export const DOWNLOAD_ALL_URL = 'DOWNLOAD_ALL_URL';
export const FETCH_NAVIGATION = 'FETCH_NAVIGATION';
export function fetchNavigation(onlyGetUnlockedFile = false, updatedProfile = {}) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_NAVIGATION, fetchingNavigation: true });
    const { vault: { fileSharingUrl }, preview: { readonly, profile }, ui: { isEuRequest, userType } } = getState();
    let previewProfile = { ...profile, ...updatedProfile };
    if (Object.keys(previewProfile).length === 0) previewProfile = null;

    get(fileSharingUrl, { params: { userType, readonly, previewProfile } }).then((data) => {
      const rootFolder = data.rootFolder;
      rootFolder.isRoot = true;
      const result = getValidFileAndNavigation(data, onlyGetUnlockedFile, isEuRequest);
      const currentFolderId = parseAndSetCurrentFolderId(result.currentFolderId);

      dispatch({ type: DOWNLOAD_ALL_URL, downloadAllUrl: data.downloadAllUrl });
      dispatch({ type: FETCH_NAVIGATION, fetchingNavigation: false });
      dispatch({ type: HANDLE_NAVIGATION, navigation: { currentFolderId, rootFolder }, file: result.file });
    });
  };
}

function getValidFileAndNavigation(data, onlyGetUnlockedFile, isEuRequest) {
  let file = null;
  let currentFolderId = null;
  const gainApprovalAccess = data.rootFolder.children.find(({ accessIfApproved }) => accessIfApproved);
  if (getQueryParameter('view_locked_docs') && gainApprovalAccess?.id && !isEuRequest) {
    setQueryParameter('file', gainApprovalAccess.id);
    setQueryParameter('view_locked_docs');
    return { file: gainApprovalAccess, currentFolderId };
  }

  const folderId = getQueryParameter('folder');
  const folderResult = findFolderInFolder(data.rootFolder, folderId);
  let folderToSearch = data.rootFolder;
  if (folderId && folderResult?.folder) {
    setQueryParameter('folder', null);
    folderToSearch = folderResult.folder;
    currentFolderId = folderResult.folder.id;
  }

  const result = getFileFromQueryOrSearchForFirst(folderToSearch, onlyGetUnlockedFile || isEuRequest);
  if (result?.file) {
    file = result.file;
    if (result.currentFolderId) currentFolderId = result.currentFolderId;
  }

  return { file, currentFolderId };
}

function getFileFromQueryOrSearchForFirst(folder, onlyGetUnlockedFile) {
  let file = null;
  let currentFolderId  = null;
  const fileId = getQueryParameter('file');
  const result = findFileInFolder(folder, fileId);

  if (result?.file && (!result.file.locked || !onlyGetUnlockedFile)) {
    file = result.file;
    currentFolderId = result.parentFolderId;
  }

  if (!file) file = getFirstFile(folder);

  if (file) {
    setQueryParameter('file', file.id);
  } else {
    setQueryParameter('file', null);
  }

  return { file, currentFolderId };
}

export const BROKER_REMINDED_AT = 'BROKER_REMINDED_AT';

export function brokerRemindedAt(timestamp) {
  return (dispatch) => {
    dispatch({ type: BROKER_REMINDED_AT, timestamp });
  };
}

export const BROKER_REMINDED_DATA = 'BROKER_REMINDED_DATA';

export function brokerRemindedData(timestamp, url) {
  return (dispatch) => {
    dispatch({ type: BROKER_REMINDED_DATA, timestamp, url });
  };
}
