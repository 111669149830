import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { setUserType } from '../../actions';
import { USER_TYPE_BROKER, USER_TYPE_OWNER, isValidUserType, labelForUserType } from '../../actions/ui';

// TODO: probably good to collapse (or even hide) the sidebar so user isn't distracted with other flows
// TODO: save their choice (in the session?) so reloading doesn't re-prompt
export function AskUserType({ nextStep, onNext, userType, setUserType }) {
  const handleChoice = (choice) => {
    setUserType(choice);
  };

  useEffect(() => {
    if (isValidUserType(userType)) {
      onNext(nextStep);
    }
  }, [userType]);

  return (
    <div className="py-2">
      <div className="text-center">
        <h5 className="mb-4">Are you a ...</h5>
      </div>
      <div className="d-flex justify-content-center">
        <Button className="me-4 w-100" variant="primary" onClick={() => handleChoice(USER_TYPE_BROKER)}>
          {labelForUserType(USER_TYPE_BROKER)}
        </Button>
        <Button className="ms-4 w-100" variant="primary" onClick={() => handleChoice(USER_TYPE_OWNER)}>
          {labelForUserType(USER_TYPE_OWNER)}
        </Button>
      </div>
      <div>
        <a className="d-flex justify-content-center mt-6 previous-account js-login" href="#">
          Do you have an existing Connect account?
        </a>
      </div>
    </div>
  );
}

AskUserType.propTypes = {
  nextStep: PropTypes.string,
  setUserType: PropTypes.func.isRequired,
  userType: PropTypes.string,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ ui }) => ({ userType: ui.userType });
const mapDispatchToProps = { setUserType };

export default connect(mapStateToProps, mapDispatchToProps)(AskUserType);
