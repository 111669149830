import {
  SET_SHOW_LOGIN, SET_SHOW_INFO_REQUEST, SET_SHOW_NO_DOCS_BANNER, SET_USER_TYPE, isValidUserType
} from '../actions/ui';
import { BROKER_REMINDED_AT, BROKER_REMINDED_DATA, DOWNLOAD_ALL_URL, FETCH_NAVIGATION } from '../actions';

export const DEFAULT_UI = {
  fetchingNavigation: false,
  showLogin: false,
  showRequestMoreInfo: false,
  showNoDocsBanner: null
};

export default function(state = DEFAULT_UI, action) {
  switch (action.type) {
  case SET_SHOW_LOGIN:
    return { ...state, showLogin: action.payload, showRequestMoreInfo: false };
  case SET_SHOW_INFO_REQUEST:
    return { ...state, showRequestMoreInfo: action.payload };
  case SET_USER_TYPE: // eslint-disable-line no-case-declarations
    // userType is used for fast-track CA
    const userType = action.payload;
    if (userType === null || isValidUserType(userType)) {
      return { ...state, userType };
    } else {
      console.error('Ignoring invalid user type:', userType); // eslint-disable-line no-console
      return state;
    }
  case SET_SHOW_NO_DOCS_BANNER:
    // payload can be null (initial), true (show), false (hide until page reload)
    if (state.showNoDocsBanner === false) {
      return state;
    } else {
      return { ...state, showNoDocsBanner: action.payload };
    }
  case BROKER_REMINDED_AT:
    return { ...state, lastRequested: { ...state?.lastRequested, timestamp: action.timestamp } };
  case BROKER_REMINDED_DATA:
    return { ...state, lastRequested: { timestamp: action.timestamp, url: action.url } };
  case DOWNLOAD_ALL_URL:
    return { ...state, downloadAllUrl: action.downloadAllUrl };
  case FETCH_NAVIGATION:
    return { ...state, fetchingNavigation: action.fetchingNavigation };
  default:
    return { ...DEFAULT_UI, ...state };
  }
}
