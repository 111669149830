import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import NewTemplateModal from './new_template_modal';
import EditTemplateModal from './edit_template_modal';
import TemplateList from './template_list';
import { deleteDataExportTemplate } from '../../helpers/connect_api';

export default function DataExportTemplateSettings({ columnOptions, templates }) {
  const [showModal, setShowModal] = useState(false);
  const [templateList, setTemplateList] = useState(templates);
  const [editingTemplate, setEditingTemplate] = useState(null);

  const handleTemplateAdded = (template) => {
    setTemplateList([...templateList, template]);
  };

  const handleDeleteTemplate = (template) => {
    if (confirm(`Are you sure you want to delete template "${template.name}"?`)) {
      deleteDataExportTemplate(template.id).then(() => {
        setTemplateList(templateList.filter(t => t.id !== template.id));
      });
    }
  };

  const handleEditTemplate = (template) => {
    setEditingTemplate(template);
  };

  const handleTemplateUpdated = (template) => {
    setTemplateList(templates.map((t) => {
      if (t.id == template.id) return template;

      return t;
    }));
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mt-5">
        <h4 className="my-auto">Export Templates</h4>

        <Button variant="secondary" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faPlus}/>
          New Template
        </Button>

        <NewTemplateModal
          columnOptions={columnOptions}
          show={showModal}
          onHide={() => setShowModal(false)}
          onTemplateAdded={handleTemplateAdded}
        />
        <EditTemplateModal
          columnOptions={columnOptions}
          template={editingTemplate}
          onHide={() => setEditingTemplate(null)}
          onTemplateUpdated={handleTemplateUpdated}
        />
      </div>
      <TemplateList
        templates={templateList}
        onDeleteTemplate={handleDeleteTemplate}
        onEditTemplate={handleEditTemplate}
      />
    </React.Fragment>
  );
}

DataExportTemplateSettings.propTypes = {
  columnOptions: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired
};
