import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';

import ResultCard from './results/result_card';
import Paginator from './results/paginator';
import CriteriaDecorator from '../helpers/criteria_decorator';
import { showSaveSearchModal } from '../store/actions/ui';
import pluralize from 'pluralize';
import { EmptyState } from './results/empty_state';
import EmptyStateBadges from './results/empty_state_badges';

export function Results({
  containerRef,
  criteria,
  detailedResults,
  loading,
  offMarketSearch,
  showSaveSearchModal,
  total
}) {
  const decoratedCriteria = new CriteriaDecorator(criteria, offMarketSearch);
  const filterCount = decoratedCriteria.numCriteriaExcludingLocation();

  if (!detailedResults) {
    return (
      <div className="text-center py-4">
        <FontAwesomeIcon beatFade className="text-primary" icon={faBuildoutLogo} size="3x" />
      </div>
    );
  }

  if (detailedResults.length === 0) {
    const openSaveSearchModal = (e) => {
      e.preventDefault();
      showSaveSearchModal(true);
    };

    return (
      <div>
        <EmptyState subtitle="Expand your search to find matching listings" title="No matches" />
        <EmptyStateBadges />
        <div className="px-4">
          <Row className="my-6 align-items-center">
            <Col><hr className="m-0" /></Col>
            <Col xs="auto"><h5 className="text-muted m-0">OR</h5></Col>
            <Col><hr className="m-0" /></Col>
          </Row>
        </div>
        <p className="px-md-5 text-center">
          <a href="#" onClick={openSaveSearchModal}>
            <strong className="text-primary">Save this search</strong>
          </a>
          {' '}to get notified when exact matches are added to Buildout Connect
        </p>
      </div>
    );
  }

  const showTotal = total && filterCount > 0;
  return (
    <div>
      {showTotal && (<div className="mt-2">
        <small className="fw-semibold">{total >= 1000 ? '1,000+' : total} {pluralize('listing', total)}</small>
      </div>)}
      <div className={`${showTotal ? 'mt-2' : 'mt-3'} transition-opacity ${loading ? 'opacity-75' : 'opacity-100'}`}>
        {detailedResults.map(result => (
          <ResultCard key={result.saleListingId} result={result} />
        ))}
      </div>

      <div className="d-flex justify-content-center">
        <Paginator containerRef={containerRef} />
      </div>
    </div>
  );
}

Results.propTypes = {
  containerRef: PropTypes.object,
  criteria: PropTypes.object.isRequired,
  detailedResults: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  offMarketSearch: PropTypes.bool,
  showSaveSearchModal: PropTypes.func.isRequired,
  total: PropTypes.number
};

const mapStateToProps = ({ results, ui: { loading, offMarketSearch }, criteria }) => ({
  ...results,
  loading,
  offMarketSearch,
  criteria
});

export default connect(mapStateToProps, { showSaveSearchModal })(Results);
