import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap-5';
import { createTag, deleteTag, fetchTags } from '../../helpers/connect_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { debounce } from 'lodash';

export default function TagSettings() {
  const [tags, setTags] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    fetchTags(searchString).then((response) => {
      setTags(response['connect/tags']);
    });
  }, []);

  const debouncedSearch = useCallback(
    debounce((inputString) => {
      fetchTags(inputString).then(response => setTags(response['connect/tags']));
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(searchString);
  }, [searchString]);

  const handleRemove = (tag) => {
    if (confirm(`Delete tag "${tag.name}"?`)) {
      deleteTag(tag.id).then(() => {
        setTags(tags.filter(t => t.id != tag.id));
      });
    }
  };

  const disableCreate = useMemo(() => {
    return !searchString || tags.find(t => t.name == searchString);
  }, [searchString, tags]);

  const handleCreate = () => {
    if (disableCreate) return;

    if (confirm(`Create tag ${searchString}?`)) {
      createTag(searchString).then((response) => {
        setTags([
          ...tags,
          response['connect/tag']
        ]);
      });
    }
  };

  return (
    <div className="border rounded p-3">
      <Form.Group className="mb-2 px-2">
        <Form.Label>Search all tags</Form.Label>
        <Row>
          <Col>
            <div className="with-icon">
              <div className="icon search-icon">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <Form.Control
                type="text"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
              />
            </div>
          </Col>
          <Col xs="auto">
            <Button disabled={disableCreate} variant="link" onClick={handleCreate}>
              <FontAwesomeIcon icon={faPlus}/>
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <div className="px-2">
        {tags.map(tag => (
          <Row className="py-2 border-bottom align-items-center" key={tag.name}>
            <Col>
              {tag.name}
            </Col>
            <Col xs="auto">
              <Button variant="link" onClick={() => handleRemove(tag)}>
                <FontAwesomeIcon className="text-danger" icon={faTrash} />
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}
