import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Dropdown, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import UnstyledDropdownToggle from 'components/bootstrap_extensions/unstyled_dropdown_toggle';

export default class Button extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.node,
    dropdownMenuItems: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({
          children: PropTypes.node,
          label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
          ]),
        }),
      ])
    ),
    dropdownMenuRightAligned: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.object,
    iconRight: PropTypes.object,
    label: PropTypes.string,
    outlined: PropTypes.bool,
    plain: PropTypes.bool,
    tooltipProps: PropTypes.object,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: () => {},
  }

  render() {
    const {
      children,
      className,
      disabled,
      disabledTooltip,
      dropdownMenuItems,
      dropdownMenuRightAligned,
      href,
      icon,
      label,
      outlined,
      plain,
      tooltipProps,
      onClick,
      ...props
    } = this.props;

    props.className = classNames('ds-button', this.buttonClass, className, {
      'ds-button--disabled': disabled,
      'ds-button--filled': !outlined && !plain,
      'ds-button--outlined': outlined,
      'ds-button--plain': plain,
    });

    const hasDisabledTooltip = disabled && disabledTooltip;
    if (!hasDisabledTooltip) {
      props.disabled = disabled;
      props.onClick = onClick;
    }

    let iconRight = this.props.iconRight;
    delete props.iconRight;
    if (dropdownMenuItems) {
      iconRight = faChevronDown;
    }

    props['aria-label'] = props['aria-label'] || label;

    // TODO: A cleaner interface would be:
    //   * Only use the `label` prop for the button label
    //   * Use `children` for dropdown menu items instead of `dropdownMenuItems`
    let button = (
      <button {...props}>
        {icon && <FontAwesomeIcon className="ds-button__icon" icon={icon} />}
        {children || label}
        {iconRight && <FontAwesomeIcon className="ds-button__icon ds-button__icon--right" icon={iconRight} />}
      </button>
    );

    if (href) {
      button = <a className="ds-button__link" href={href}>{button}</a>;
    }

    if (hasDisabledTooltip) {
      button = (
        <OverlayTrigger
          overlay={<Tooltip id="disabled-button-tooltip">{disabledTooltip}</Tooltip>}
          placement="bottom"
          {...tooltipProps}
        >
          <span>{button}</span>
        </OverlayTrigger>
      );
    }

    if (dropdownMenuItems) {
      const toggleClasses = classNames('toggle', { disabled });
      button = (
        <Dropdown id="ds-button--dropdown">
          <UnstyledDropdownToggle bsRole={toggleClasses}>
            {button}
          </UnstyledDropdownToggle>
          <Dropdown.Menu
            className={classNames({ 'dropdown-menu-right': dropdownMenuRightAligned })}
          >
            {dropdownMenuItems.map((menuItem, i) => {
              let menuItemChildren, menuItemProps;
              if (React.isValidElement(menuItem)) {
                menuItemChildren = menuItem;
              } else {
                const { label, ...nestedProps } = menuItem;
                menuItemChildren = children || label;
                menuItemProps = { ...nestedProps, 'aria-label': label };
              }

              // Bootstrap's Dropdown.Menu requires its direct children to be Bootstrap MenuItems components
              // Extractsing this to a separate comopnent will break automatic menu close on click
              return (
                <MenuItem key={i} {...menuItemProps}>
                  {menuItemChildren}
                </MenuItem>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return button;
  }
}
